footer{
    .footer__container{
        padding: 30px 20px 20px 20px;

        .footer__links{
            margin-bottom: 10px;
            a{
                color: $color-light;
                margin: 5px 10px 10px 10px;
                font-weight: 700;
                text-decoration: underline;
            }
        }
        .footer__copyright{
            p{
                margin-bottom: 0;
            }
        }
    }
}