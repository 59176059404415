@keyframes moveInBottom {
  0% {
    opacity: 0;
    transform: translateY(3ren);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.navbar.navbar-light {
    &::after {
        display: block;
        width: 100%;
        height: 10px;
        position: absolute;
        bottom: -10px;
        content: '';
        /* background-color: red; */
        left: 0;
        background-image: linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0));
    }
    //background-color: darken($color-primary, 15%);
    padding-top: 5px;
    padding-bottom: 0;
    background-color: #FFF;
    position: absolute;
    position: sticky;
    z-index: 1000;
    top: 0;
    width: 100%;
    background-color: #FFF;
    background-color: rgba(255,255,255,0.99) !important;
    border: #f79a1a;
    font-size: 2rem;
    .navbar-logo {
      width: 40px;
      margin-right: 10px;
      @media screen and (min-width: 768px){
        height: 30px;
        width: 50px;
      }
      
    }
    .navbar-brand{
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      
        font-size: 1.25rem;
        color: $color-sec !important;
        font-weight: normal !important;
        &:hover{
            color: $color-pri !important;
        }
        span{
          font-weight: 700;
          transition: background-color 0.5s, color 0.5s;
          @media screen and (min-width: 768px){
            font-size: 1.3rem;
          }
        }
    }
    a.nav-link {
      //color: $color-neutral !important;
      color: $color-sec;
      font-weight: 700;
      font-size: 1.25rem;
      border-radius: 4px;
      padding-top: 2px;
      padding-bottom: 2px;
      display: inline;
      border: 3px solid transparent;
      transition: background-color 0.5s, color 0.5s;
      margin-right: 0;
      margin-bottom: 10px;
      @media screen and (min-width: 768px) {
        font-size: 1.3rem;
        margin-bottom: 5px;
        margin-right: 10px;
      }
      &:hover {
        color: $color-pri !important;
      }
    
      &.active {
        // border-bottom: 3px solid $color-pri !important;
        // padding-bottom: 0;
        color: $color-pri !important;
        font-weight: bold;
        position: relative;

        &::after{
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 3px;
          background-color: #F79A1A;
          left: 0;
          bottom: -3px;
        }
      }
    }
    a.nav-link.nav-contact{
        border-radius: 30px;
        background-color: $color-pri;
        color: $color-light !important;
        border-color: $color-pri;

        &:hover{
            background-color: $color-light;
            color: $color-pri !important;
        }
        &::after{
          display: none;
        }
    }
  
    .navbar-toggle {
      background-color: transparent;
    }
    .navbar-collapse {
      animation: moveInBottom 1s ease-out 0s;
    }
  }

#navbar__links{
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    float: none;
    

    @media screen and (min-width: 768px){
      flex-direction: row;
      margin-right: 20px;
    }

    &.collapse{
        display: none;
    }

    @media screen and (min-width:768px){
        &.navbar-collapse{
            display: inline-block;
            float: right;
        }
    }
}
.navbar-toggle{
    position: relative;
    float: right;
    margin-right: 15px;
    padding: 9px 10px;
    margin-top: 8px;
    margin-bottom: 8px;
    background-color: transparent;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;

    display: inline-block;
    @media screen and (min-width:768px){
        display: none;
    }

    & .icon-bar{
        transition: opacity 0.2s, transform 0.2s;
        display: block;
        margin-bottom: 4px;
		background-color: #f79a1a;
		// background: -webkit-radial-gradient(#EBC17D 0%, #E7BA75 36%, #BE7129 100%);
		// background: radial-gradient(#EBC17D 0%, #E7BA75 36%, #BE7129 100%);

		width: 30px;
		height: 5px;
	  }

    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
    }
   
    &[aria-expanded="true"]{
        & span:nth-child(2){
            transform-origin:center;
            transform: translateY(0px) rotate(45deg);
        }
        & span:nth-child(3){
          transform-origin:center;
          transform: translateY(-9px) rotate(-45deg);
      }
      & span:nth-child(4){
          display:none;
          opacity:0;
      }
    }
}
button.custom-toggle.navbar-toggle[aria-expanded="true"] {
  // padding-top: 0;
  margin-top: 17px;
}
