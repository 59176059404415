
//COLORS:
$color-pri: #F79A1A;
$color-pri--light: #FFC242;
$color-sec: #0074E1;
$color-light: #FFF;
$color-neutral: #AAA;
$color-dark: #000;
//$color-dark: #8ec45b;

// breakpoints
$mobile: 768px;
$tablet: 992px;
$desk: 1440px;

//bootstrap 
// $font-size-base: 18px;