
.resume{
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    padding: 50px 0;
}
.resume__content{
    max-width: 750px;
    margin: 0 auto;
    padding: 40px;
    box-shadow: 0 10px 8px #000;
    border-radius: 15px;
    // padding-bottom: 20px;
    background-color: $color-light;
    background-color: rgba(255,255,255,0.93);
    height: calc(100% - 15px);
    transition: transform 0.25s;
    opacity: 0;
    animation: portfolioFadeIn 0.5s normal forwards ease-in;
}
.resume__skill-list{
    @media screen and (min-width: 768px){
        columns: 3;
    }
}
.resume__bio-header{

}
.resume__skill-header{

}
.resume__work-header{

}