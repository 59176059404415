@keyframes fadeIn{
    from{
        opacity: 0;
    }
    to{
        opacity: 1;
    }
}

html{
    scroll-behavior: smooth;
    font-size: 18px;
}

ul{
    padding-left: 25px;

    li{
        margin-bottom: 10px;
    }
}
hr{
    border-top: 1px solid #ccc;
}
hr.hr--overflow{
    border-top: 1px solid #ccc;
    position: relative;
    &::before{
        display: block;
        height: 1px;
        background-color: #ccc;
        width: 40px;
        top: -1px;
        left: -40px;
        position: absolute;
        content: "";
    }
    &::after{ 
        display: block;
        height: 1px;
        background-color: #ccc;
        width: 40px;
        top: -1px;
        right: -40px;
        position: absolute;
        content: "";
    }
}

.no-underline{
    text-decoration: none;
}
.text-center{
    text-align: center;
}

.link--orange{
    font-weight: 700;
    color: $color-pri;
    transition: color 0.5s;
    text-decoration: underline;
    &:hover{
        color: $color-pri--light;
    }
   
}

//animation classes
.fadeIn{
    //opacity:0;
    animation: 0.5s fadeIn linear;
}

// background colors
.bg--pri{
    background-color: $color-pri;
}
.bg--sec{
    background-color: $color-sec;
}
.bg--light{
    background-color: $color-light;
}
.bg--dark{
    background-color: $color-dark;
}
.bg-neutral{
    background-color: $color-neutral;
}
.bg--transparent{
    background-color: transparent;
}

//text colors
.text--pri{
    color: $color-pri;
}
.text--sec{
    color: $color-sec;
}
.text--light{
    color: $color-light;
}
.text--dark{
    color: $color-dark;
}
.text-neutral{
    color: $color-neutral;
}

//baner
.page-banner{
    padding: 60px 20px;
    background-color:#0074E1;
    background: -webkit-linear-gradient(to top, #0074e1,#1b9ce5, #6cdaee); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to top, #0074e1,#1b9ce5, #6cdaee); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

//buttons
.btn--pri {
    transform-origin: center;
    border: 3px solid $color-pri;
    background: $color-pri;
    transition: background-color 0.5s, color 0.5s !important;
    color: white;
    padding: 10px 20px;
    border-radius: 30px;
    font-size: 17px;
    text-decoration: none !important;
    font-weight: bolder;
  }
  .btn--pri:hover {
    color: $color-pri;
    background: white;
  }