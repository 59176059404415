@keyframes blink {
    0% {
      opacity: 0;
    }
    49% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
  @keyframes bubbleFloat {
    0% {
      top: 100%;
      opacity: 0;
    }
    10% {
      transform: translateX(-5px);
      opacity: 1;
    }
    20% {
      transform: translateX(5px);
    }
    30% {
      transform: translateX(-5px);
    }
    40% {
      transform: translateY(5px);
    }
    50% {
      transform: translateX(-5px);
    }
    60% {
      transform: translateX(5px);
    }
    70% {
      transform: translateX(-5px);
    }
    80% {
      transform: translateX(5px);
    }
    90% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(5px);
      top: -50px;
    }
  }
  @keyframes swim {
    0% {
      right: calc(100%);
      opacity: 0;
    }
    10% {
      transform: translateY(-5px);
      opacity: 1;
    }
    20% {
      transform: translateY(5px);
    }
    30% {
      transform: translateY(-5px);
    }
    40% {
      transform: translateY(5px);
    }
    50% {
      transform: translateY(-5px);
    }
    60% {
      transform: translateY(5px);
    }
    70% {
      transform: translateY(-5px);
    }
    80% {
      transform: translateY(5px);
    }
    90% {
      transform: translateY(-5px);
      opacity: 1;
    }
    100% {
      transform: translateY(5px);
      right: 0px;
      opacity: 0;
    }
  }
  @keyframes buttonFloat {
    /*===Buttons===*/
    0% {
      transform: rotate(1deg);
    }
    25% {
      transform: rotate(-1deg);
    }
    50% {
      transform: rotate(1deg);
    }
    75% {
      transform: rotate(-1.5deg);
    }
    100% {
      transform: rotate(1deg);
    }
  }

  @keyframes fadeDown{
    0%{
      opacity: 0;
      transform: translateY(-10px) rotate(48deg);
    }
    20%{
      opacity: 0.5;
      transform: translateY(-5px) rotate(42deg);
    }
    40%{
      opacity: 1;
      transform: translateY(0px) rotate(45deg);
    }
    60%{
      opacity: 1;
      transform: translateY(0px) rotate(45deg);
    }
    80%{
      opacity: 0.5;
      transform: translateY(5px) rotate(48deg);
    }
    100%{
      opacity: 0;
      transform: translateY(10px) rotate(42deg);
    }
  }
  @keyframes washUp{
    0% {
      opacity: 0;
      transform: scale(0.5);
    }
    10% {
      transform: translate(-2px);
      
    }
    20% {
      transform: translate(2px);
    }
    30% {
      transform: translate(-2px) scale(1);
      opacity: 1;
    }
    40% {
      transform: translate(0);
    }
    50% {
    }
    60% {
    }
    70% {
      transform: translate(0);
      opacity: 1;
    }
    80% {
      transform: translate(2px);
    }
    90% {
      transform: translate(-2px);
    }
    100% {
      transform: translate(2px) scale(0.5);
      opacity: 0;
    }
  }
  
  /*===MEDIA QUERIES===*/
  @media (max-width: 979px) and (min-width: 768px){
  #page #site-header .site-title {
    padding-top: 5px;
  }
}
.home-washup-text{
  opacity: 0;
  animation: washUp 5s linear normal;
}
  .home-subheader p {
    display: inline-block;
    width: 33%;
  }
  .home-subheader {
    transform: translateY(calc(50vh - 50% - 35px));
  }
  .home-subheader p {
    position: relative;
  }
  .home-subheader a {
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
  }

  @media all and (min-width: 1100px) {
    /*Forms*/  .home-subheader a {
      
      position: absolute;
      transform-origin: center;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      //transform: translateX(-50%);
      max-width: 200px;
      opacity: 1;
      pointer-events: all;
      transition: opacity 1s;
      &:hover{
        animation: buttonFloat 6s linear infinite;
      }
    }
  }
  #gform_submit_button_1 {
    font-family: Raleway;
    text-transform: none;
    background-color: #f79e02;
  }
  #gform_wrapper_1 label {
    font-family: Raleway;
  }
  
  #home-header-section{
    text-align: center;
    background-color:#0074E1;
     background: -webkit-linear-gradient(to top, #373b44,#0074E1, #0074E1); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #373b44,#0074E1, #0074E1); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: -webkit-linear-gradient(to top, #0074e1,#1b9ce5, #6cdaee); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to top, #0074e1,#1b9ce5, #6cdaee); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    
}
#home-header-section h1, #home-header-section h1::after{
    color:white;
    text-transform:none;
}
#home-header{
    transform: translateY(calc(50vh - 50% - 50px));
}
#home-section a{
    transform: translateY(calc(50vh - 10px));
}
// #home-header-section *:not(span):not(h1):not(p):not(a):not(.home-subheader):not(.home-hero__explore){
// 	height:calc(100vh - 70px) !important;
// }

#home-header-section .home-header__container{
  height:calc(100vh - 55px) !important;
}

#home-header-section .home-hero__explore{
  position: absolute;
  display: flex;
  bottom: 0;
  height: 50px;
  z-index: 3;
  color: $color-light;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);

  &::after{
    height: 20px;
    width: 20px;
    // background: $color-light;
    content: "";
    display: inline-block;
    margin-top: 10px;
    border-right: 3px solid $color-light;
    border-bottom: 3px solid $color-light;
    transform: rotate(45deg);

    animation: fadeDown 7s linear infinite;
  }
}

// #bubble-1{
// 	left:0;
// 	position:absolute;
// 	font-weight:bold;
// 	color:white !important;
// 	animation: bubbleFloat 6s linear infinite;
// 	-webkit-animation:bubbleFloat 6s linear infinite; /* Safari & Chrome */
//     -moz-animation:bubbleFloat 6s linear infinite; /* Firefox */
//     -ms-animation:bubbleFloat 6s linear infinite; /* Internet Explorer */
//     -o-animation:bubbleFloat 6s linear infinite; /* Opera */
// }
// #bubble-2{
// 	position:absolute;
// 	font-weight:bold;
// 	color:white !important;
// 	animation: bubbleFloat 5s linear infinite;
// 	-webkit-animation:bubbleFloat 5s linear infinite; /* Safari & Chrome */
//     -moz-animation:bubbleFloat 5s linear infinite; /* Firefox */
//     -ms-animation:bubbleFloat 5s linear infinite; /* Internet Explorer */
//     -o-animation:bubbleFloat 5s linear infinite; /* Opera */
// 	left:50%;
// }
#bubble-3{
	left:75%;
	position:absolute;
	font-weight:bold;
	color:white !important;
  opacity: 0;
	animation: bubbleFloat 6.4s linear infinite;
  animation-delay: 1s;
}
// #bubble-4{
// 	left:90%;
// 	position:absolute;
// 	font-weight:bold;
// 	color:white !important;
// 	animation: bubbleFloat 5.8s linear infinite;
// 	-webkit-animation:bubbleFloat 5.8s linear infinite; /* Safari & Chrome */
//     -moz-animation:bubbleFloat 5.8s linear infinite; /* Firefox */
//     -ms-animation:bubbleFloat 5.8s linear infinite; /* Internet Explorer */
//     -o-animation:bubbleFloat 5.8s linear infinite; /* Opera */
// }
#bubble-5{
	left: 25%;
	position:absolute;
	font-weight:bold;
	color:white !important;
  opacity: 0;
	animation: bubbleFloat 6.7s linear infinite;
  animation-delay: 1.5s;
}
.fish{
    color:#F79E02;
	font-weight:bold;
	position:absolute;
	white-space:nowrap;
	letter-spacing:-6px;
}
#fish-1{
	top:25%;
	font-size:24px;
	animation: swim 17s linear infinite;
  opacity: 0;
  animation-delay :1.5s;
}
#fish-2{
	top:80%;
	font-size:30px;
	animation: swim 12s linear infinite;
  opacity: 0;
  animation-delay: 1s;
}
// #fish-3{
// 	top:40%;
// 	font-size:19px;
// 	animation: swim 15s reverse infinite;
// }
// #home-header::after{
// 	content:'_';
// 	animation: blink 2s linear infinite;
// }
.button-display-third{
    transform:translateY(calc(50vh  - 30px));
}
.button-display-third > p {
    width:33%;
    display:inline-block;
}
/*intro section*/
#home-intro-section{
    padding-bottom: 50px;
}
#home-intro-section a{
    // text-decoration:underline;
    // font-weight:bold;
    // color:#F79E02;
}
#home-intro-section p{
    // font-size:20px;
}
#portrait-col img{
    border-radius: 0 0 50% 50%;
    background: #0074E1;
    padding-top: 60px;
}
#home-intro-section .vc_col-sm-6{
    margin-top:0 !important;
}
/*portfolio section*/
#portrait-col{
    img{
        max-width: 100%;
    }
}
#home-portfolio-section{
    padding-top: 80px;
    padding-bottom: 30px;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
#home-portfolio-section a{
    color:#F79E02;
    font-weight:bold;
    text-decoration:underline;
}
#home-portfolio-section h2{
    //font-size:20px;
    text-shadow:0 0 6px black;
}