// @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://cdn.jsdelivr.net/npm/@fortawesome/fontawesome-free@5.15.3/css/fontawesome.min.css');

h1,h2,h3,h4,h5,h6{
    font-family: 'Roboto', sans-serif;
    font-weight: 700 !important;
}
body{
    font-family: 'Roboto', sans-serif;
}