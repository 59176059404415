// @keyframes waves{
//     0%{
//         backgroun
//     }
// }

@keyframes portfolioFadeIn{
    from{
        opacitiy: 0;
    }
    to{
        opacity: 1;
    }
}

.portfolio-intro{
    padding: 30px 0;
}
.projects{
    padding: 50px 0 80px 0;
    background-attachment: fixed;
    background-size: cover;
    min-height: calc(100vh - 100px);

}
.projects__col{
    padding-bottom: 50px;
}
.projects__project-img{
    border-radius: 15px 15px 0 0;
    margin-bottom: 10px;
    width: 100%;
    height: 200px;
    object-fit: cover;
}
.projects__poject-item-header{
    margin-top: 10px;
}
.projects__project-item{
    margin-bottom: 50px;
    box-shadow: 0 10px 8px #000;
    border-radius: 15px;
    // padding-bottom: 20px;
    background-color: $color-light;
    background-color: rgba(255,255,255,0.93);
    height: calc(100% - 15px);
    transition: transform 0.25s;
    opacity: 0;
    animation: portfolioFadeIn 0.5s normal forwards ease-in;

    &:hover{
        transform: scale(1.05);
    }
}

.projects__description{
    margin-bottom: 30px;
}

.projects__project-link{
    margin-top: 20px;
    margin-bottom: 20px;
}

.loading-img{
    margin: 20px auto;
    padding: 20px;
    width: 100px;
    height: 100px;
    border-radius: 15px;
    background-color: rgba(255, 255, 255, 0.7);
}

.projects__content{
    padding: 0 10px;

    &:hover::after{
        animation: waves 2s linear infinite;
        
    }
}