@import "partials/globalVariables.scss";
@import "partials/fonts";

//bootstrap
@import "~bootstrap/scss/bootstrap";

//partials
@import "partials/navbar";
@import "partials/footer";
@import "partials/home";
@import "partials/portfolio";
@import "partials/resume";
@import "partials/404";
@import "partials/common";

